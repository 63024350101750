import React, { useState, useEffect } from "react";
import MainCard from "components/MainCard";
import {
  Tabs,
  Table,
  Modal,
  Input,
  Pagination,
  message,
  Popconfirm,
} from "antd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import { useNavigate } from "react-router-dom";
import CommentIcon from "@mui/icons-material/Comment";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import {
  bp_get_sample_detailss,
  bp_get_sampling_details_by_sample,
  bp_save_sample_actual_data,
  store_sample_detail_status,
  resampling_sample,
} from "utils/api";
import {
  IconButton,
  Button,
  OutlinedInput,
  Stack,
  InputLabel,
  Tooltip,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import InfoComponent from "components/Info";
function SampleTesting() {
  const [sample_data, set_sample_data] = useState([]);
  const [result_value, set_result_value] = useState([]);
  const [sample_detail_id, set_sample_detail_id] = useState("");
  const [paramter_details, set_paramter_details] = useState([]);
  const [searchvalue, setSearchvalue] = useState("");
  const [rejectComment, setRejectComment] = useState("");
  const [commentshow, setCommentshow] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState();
  const navigate = useNavigate();
  const get_data = async () => {
    const req = {
      page_number: page_number,
      ...(searchvalue && { search: searchvalue }),
    };
    try {
      await bp_get_sample_detailss(req, success_bp_get_sample_detailss);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_sample_detailss = (res) => {
    set_sample_data(res?.data?.data || []);
    set_total(res?.data?.obj_count);
  };

  const update_sampling_result = async (req) => {
    try {
      await store_sample_detail_status(req, success_bp_update_sample);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_update_sample = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      setRejectComment("");
      setCommentshow(false);
      get_data();
    } else {
      toast.warning(res?.message);
    }
  };

  const sampleconfirm = (record) => {
    update_resampling_sample(record?.sample_detail_id);
  };

  const modifyConfirm = (record) => {
    navigate("/edit-batch", {
      state: {
        batch_number: record?.batch_number,
        recipe: record?.recipe_id,
        recipeName: record?.recipe_name,
      },
    });
    window?.localStorage?.removeItem("editRecipe", "");
  };

  const update_resampling_sample = async (sampleId) => {
    const req = {
      sample_details_id: sampleId,
    };
    try {
      await resampling_sample(req, success_bp_resampling_sample);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_resampling_sample = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_data();
    } else {
      toast.warning(res?.message);
    }
  };

  const confirm = (record) => {
    console.log(record);
    const req = {
      sample_detail_id: record?.sample_detail_id,
      sample_number: record?.sample_number,
      batch_number: record?.batch_number,
      operation_id: record?.operation_id,
      batch_id: record?.batch_id,
      sample_status: true,
    };
    update_sampling_result(req);
  };
  const reject = (record) => {
    const req = {
      sample_detail_id: record?.sample_detail_id,
      sample_number: record?.sample_number,
      batch_number: record?.batch_number,
      operation_id: record?.operation_id,
      batch_id: record?.batch_id,
      sample_status: false,
      description: rejectComment,
    };
    update_sampling_result(req);
  };

  const get_parameter_details = async (sample_detail_id) => {
    try {
      await bp_get_sampling_details_by_sample(
        success_bp_get_sampling_details_by_sample,
        {
          sample_detail_id: sample_detail_id,
        }
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_sampling_details_by_sample = (res) => {
    set_paramter_details(res?.data?.data);
  };

  const [show_modal, set_show_modal] = useState(false);
  const [total, set_total] = useState("");
  const [page_number, set_page_number] = useState(1);

  const submit_function = async (metaData) => {
    try {
      await bp_save_sample_actual_data(
        {
          sample_detail_id: sample_detail_id,
          observation: "",
          data_flow: result_value?.map((value, index) => {
            return {
              sampling_config_id: paramter_details[index]?.sampling_config_id,
              value: value,
              remarks: "remarks",
            };
          }),
        },
        success_bp_save_batch_parameters
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_save_batch_parameters = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success("Parameter value added successfully");
      set_show_modal(false);
      get_data();
    }
  };

  const sample_data_column = [
    {
      title: "Date Recieved",
      dataIndex: "date_recieved",
      key: "date_recieved",
      render: (_, record) => {
        return record?.date_time?.slice(0, 19);
      },
    },
    {
      title: "Sample Number",
      dataIndex: "sample_number",
      key: "sample_number",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record.sample_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.date_recieved)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.batch_number)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.recipe_name).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Batch Number ",
      dataIndex: "batch_number",
      key: "batch_number",
    },
    {
      title: "Recipe",
      dataIndex: "recipe_name",
      key: "recipe",
    },
    {
      title: "Testing Type",
      dataIndex: "testing_type_id",
      key: "testing_type",
    },
    {
      title: "Machine",
      dataIndex: "machine_id",
      key: "machine_id",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (data, record, index) => {
        return (
          <span>
            {data === "Completed"
              ? "Passed"
              : data === "Rejected"
              ? "Failed"
              : data}
          </span>
        );
      },
    },
    {
      title: "Results",
      dataIndex: "",
      key: "ingredients",
      align: "center",
      render: (_, record, index) => {
        return (
          <IconButton
            onClick={() => {
              set_show_modal(true);
              set_sample_detail_id(record?.sample_detail_id);
              get_parameter_details(record?.sample_detail_id);
            }}
          >
            <VisibilityIcon
              color={
                record?.status === "Completed"
                  ? "success"
                  : record?.status === "Rejected"
                  ? "error"
                  : "primary"
              }
            />
          </IconButton>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (_, record, index) => {
        return record?.status === "Completed" ? (
          <Tooltip title="Approved">
            <DoneOutlineIcon style={{ color: "green" }} />
          </Tooltip>
        ) : record?.status === "Rejected" ? (
          <Stack direction="row" align="center" justifyContent="center">
            <Tooltip title="Comment">
              <IconButton
                onClick={() => {
                  Modal.info({
                    title: "Comment",
                    content: <>{record?.comment || ""}</>,
                  });
                }}
              >
                <CommentIcon color="secondary" />
              </IconButton>
            </Tooltip>
            {/* <Popconfirm
              title="Are you sure you want to proceed with resampling?"
              onConfirm={() => sampleconfirm(record)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Resampling">
                <IconButton>
                  <EventRepeatIcon style={{ color: "orange" }} />
                </IconButton>
              </Tooltip>
            </Popconfirm> */}
            <Popconfirm
              title="Are you sure you want to modify the batch?"
              onConfirm={() => modifyConfirm(record)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title="Modify Batch">
                <IconButton>
                  <EditCalendarIcon style={{ color: "orange" }} />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </Stack>
        ) : record?.status === "Resampling" ? (
          <Tooltip title="Resampling">
            <EventRepeatIcon style={{ color: "green" }} />
          </Tooltip>
        ) : (
          <Stack
            direction="row"
            spacing={1}
            align="center"
            justifyContent="center"
          >
            <Popconfirm
              title="Are you sure you want to approve this?"
              onConfirm={() => confirm(record)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={"Approve"}>
                <CheckCircleIcon
                  color={"success"}
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to Reject this?"
              onConfirm={() => {
                setSelectedRecord(record);
                setCommentshow(true);
              }}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={"Failed"}>
                <CancelIcon color="error" style={{ cursor: "pointer" }} />
              </Tooltip>
            </Popconfirm>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    get_data();
  }, [page_number, searchvalue]);

  return (
    <MainCard>
      <Stack direction="row" justifyContent="end" sx={{ mb: 1 }}>
        <Input.Search
          Placeholder="Search here..."
          className="mb-1"
          size="large"
          style={{ width: "15%" }}
          onSearch={(value) => {
            setSearchvalue(value);
          }}
          onChange={(e) => setSearchvalue(e.target.value)}
        />
        <InfoComponent infoShow={true} infoText={"Sample Testing"} />
      </Stack>

      <Table
        size="small"
        bordered
        pagination={false}
        columns={sample_data_column}
        dataSource={sample_data}
      />
      <Stack direction="row" justifyContent="end" sx={{ mb: 1 }}>
        <Pagination
          total={total}
          showTotal={(total) => `Total ${total} samples`}
          current={page_number}
          showSizeChanger={false}
          onChange={(value) => {
            set_page_number(value);
          }}
        />
      </Stack>
      <Modal
        open={show_modal}
        title="Results"
        footer={[]}
        onCancel={() => {
          set_show_modal(false);
        }}
      >
        <Table
          size="small"
          bordered
          pagination={false}
          columns={[
            {
              title: "Parameter",
              dataIndex: "parameter_name",
              key: "parameter_name",
            },
            {
              title: "Expected Range",
              dataIndex: "expected_range",
              key: "expected_range",
              align: "center",
              render: (_, record) => {
                return `${record?.sampling_min_value}-${record?.sampling_max_value}`;
              },
            },
            {
              title: "Value",
              dataIndex: "parameter_value",
              key: "value",
              align: "center",
              render: (_, record, index) =>
                record.value === "" ? (
                  <OutlinedInput
                    id="des"
                    type="number"
                    size="small"
                    placeholder="Enter Value"
                    fullWidth
                    required
                    onChange={(e) => {
                      let temp = [...result_value];
                      temp[index] = e.target.value;
                      set_result_value(temp);
                    }}
                  />
                ) : (
                  record.value
                ),
            },
          ]}
          dataSource={paramter_details}
        />
        <Stack direction="row" justifyContent="end" sx={{ my: 2 }}>
          {paramter_details?.some((item) => item?.value === "") && (
            <Button
              variant="outlined"
              size="small"
              disabled={!result_value?.some(Boolean)}
              onClick={() => {
                submit_function();
              }}
            >
              Submit
            </Button>
          )}
        </Stack>
      </Modal>
      <Modal
        open={commentshow}
        title="Comment"
        footer={[]}
        onCancel={() => {
          setCommentshow(false);
        }}
      >
        <Stack direction="row" spacing={1}>
          <OutlinedInput
            id="des"
            type="text"
            size="small"
            name="des"
            placeholder="comment"
            sx={{ width: "300px" }}
            required
            value={rejectComment}
            inputProps={{ min: 0 }}
            onChange={(e) => {
              setRejectComment(e.target.value);
            }}
          />
        </Stack>
        <Stack direction="row" justifyContent="end" sx={{ my: 2 }}>
          <Button
            variant="contained"
            size="small"
            disabled={!rejectComment}
            onClick={() => {
              reject(selectedRecord);
            }}
          >
            Submit
          </Button>
        </Stack>
      </Modal>
    </MainCard>
  );
}

export default SampleTesting;
