import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Typography,
  Grid,
  Box,
  Tab,
  ButtonGroup,
  Stack,
  Chip,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Tooltip,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import { Table, Input, Modal, Select, Popconfirm, Collapse, Panel } from "antd";
import MainCard from "components/MainCard";
import { accessControlUtils } from "../../components/AccessControlUtils";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  send_recipe_approval_status,
  bp_product_data,
  get_recipe_by_product_id,
  get_api_function,
  copy_recipe_data,
} from "../../utils/api";
import TableInModal from "components/TableInModal";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import InfoComponent from "components/Info";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

function ViewRecipe({ set_flow_data }) {
  const controllerRef = useRef();
  const accessData = accessControlUtils("View Recipe");
  const [recipeData, setRecipeData] = useState([]);
  const [process_expand, set_process_expand] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [open, setIsOpen] = useState(false);
  const [sourceData, setSourceData] = useState([]);
  const [current_expand, set_current_expand] = useState();
  const [expanded, set_expanded] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [modalTitle, setModalTitle] = useState();
  const [searchvalue, setSearchvalue] = useState("");
  const [product_list, set_product_list] = useState([]);
  const { Panel } = Collapse;
  const [ingredientData, setIngredientData] = useState([
    {
      ingredients_id: 1,
      material: "Calcium ch",
      recipe_md: "Silicon",
      capacity: 800,
      capacity_unit: "m",
    },
    {
      ingredients_id: 3,
      material: "Calcium ch",
      recipe_md: "Silicon",
      capacity: 700,
      capacity_unit: "m",
    },
    {
      ingredients_id: 7,
      material: "Calcium ch",
      recipe_md: "Silicon",
      capacity: 600,
      capacity_unit: "m",
    },
    {
      ingredients_id: 8,
      material: "Calcium ch",
      recipe_md: "Silicon",
      capacity: 200,
      capacity_unit: "Liter",
    },
  ]);
  const [processData, setProcessData] = useState([]);
  const [selectedMetaData, setSelectedMetadata] = useState("");
  const [recipe_id, set_recipe_id] = useState();
  const [recipe_name, set_recipe_name] = useState("");
  const [product_id, set_product_id] = useState();
  const [recipeShow, setRecipeShow] = useState(false);
  const [metadataList, setMetadataList] = useState([]);
  const [selectedButton, setSelectedButton] = useState("test");
  const confirm = () => {
    // move_recipe_to_production();
    recipe_approval_status();
  };

  const confirmCopy = () => {
    setRecipeShow(true);
  };

  const handleClick = (button) => {
    set_product_id();
    set_recipe_id();
    setRecipeData([]);
    setMetadataList([]);
    set_flow_data();
    setSelectedButton(button);
  };

  const handleViewBom = () => {
    if (recipe_id) {
      navigate("/bom", { state: { recipeId: recipe_id } });
    }
  };

  const buttonStyles = (isSelected) => ({
    width: "100px",
    backgroundColor: isSelected ? "#1976d2" : "inherit", // Use primary.main color or change it to match your theme
    color: isSelected ? "#ffffff" : "inherit", // Use primary.contrastText color or change it to match your theme
    border: "1px solid #1976d2", // Optional: to have a consistent border color
    "&:hover": {
      backgroundColor: isSelected ? "#115293" : "inherit", // Use primary.dark color or change it to match your theme
    },
  });

  const handleOk = () => {
    setIsOpen(false);
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleRecipeCancel = () => {
    setRecipeShow(false);
    set_recipe_name("");
  };

  const handleRecipeOk = () => {
    if (recipe_id && recipe_name) {
      copy_recipe(recipe_id, recipe_name);
    }
  };

  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  const ingredientStrings = (data, header) => {
    return data?.map((ingredient, index) => {
      let ingredientString = `${ingredient.material_name} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist
        ingredientString += "";
        // ingredient.parameter_name && `(${ingredient.parameter_name}), `;"
      } else {
        ingredientString += `: ${ingredient.quantity + ""}`;
        if (ingredient.parameter_name !== "") {
          ingredientString += `-${ingredient.parameter_name}: Min ${ingredient.min_value} ${ingredient.parameter_name_unit} and Max ${ingredient.max_value} ${ingredient.parameter_name_unit}`;
        } else {
          ingredientString += `-`;
        }
      }
      ingredientString += index === data?.length - 1 ? "" : ",";
      return ingredientString;
    });
  };

  //############### Handle API's ##################

  const get_Recipe_Data = async (recipe_id) => {
    try {
      // await getProcessByRecipeId(recipe_id, successRecipeData);
      await get_api_function(
        `/get_process_list_by_recipe?recipe_id=${recipe_id}`,
        successRecipeData
      );
    } catch (e) {
      console.log(e);
    }
  };

  const get_recipe_by_productid = (product) => {
    try {
      get_recipe_by_product_id(success_recipe_by_product, {
        product_id: product,
        ...(selectedButton !== "test" && { recipe_type: true }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const success_recipe_by_product = (res) => {
    if (res?.data?.status === "success") {
      setMetadataList(res?.data?.data);
    } else {
      setMetadataList([]);
    }
  };
  const copy_recipe = (recipeid, recipeName) => {
    try {
      const req = {
        recipe_id: recipeid,
        new_recipe_name: recipeName,
      };
      copy_recipe_data(req, success_copy_recipe);
    } catch (error) {
      console.log(error);
    }
  };

  const success_copy_recipe = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      setRecipeShow(false);
    } else {
      toast.warning(res?.message);
    }
  };

  const recipe_approval_status = () => {
    try {
      send_recipe_approval_status(
        {
          recipe_id: recipe_id,
        },
        success_production_recipe
      );
    } catch (error) {
      console.log(error);
    }
  };

  const success_production_recipe = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      set_product_id();
      set_recipe_id();
      setRecipeData([]);
      setMetadataList([]);
      set_flow_data();
      bp_product_data(success_bp_product_data);
    } else {
      toast.warning(res?.message);
    }
  };
  const successRecipeData = (res) => {
    const data = res?.data?.data;
    const filteredProcesses = data?.filter((process) => {
      return process?.process_roles.every((role) => role.status === true);
    });
    setRecipeData(addKeysInFilterDataForCollapse(filteredProcesses));
    console.log(res);
  };

  const process_columns = [
    {
      title: "Process Name",
      dataIndex: "process_name",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return String(record.process_name)
          ?.toLowerCase()
          ?.includes(value.toLowerCase());
        // String(record.process_roles).toLowerCase().includes(value.toLowerCase()) ||
        // String(record.status).toLowerCase().includes(value.toLowerCase())
      },
    },
    {
      title: "Process Roles",
      dataIndex: "process_roles", // Assuming the key in your data for process_roles is "process_roles"
      key: "process_roles",
      align: "center",
      render: (processRoles) => (
        <>
          {processRoles?.map((role, index) => (
            <span key={index}>
              <Chip
                sx={{
                  m: 0.5,
                }}
                label={role.role_name}
              />
            </span>
          ))}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "title",
      align: "center",
      render: (processRoles) => <>{"Approved"}</>,
    },
    // {
    //   title: "Copy",
    //   dataIndex: "",
    //   key: "title",
    //   align: "center",
    //   render: (data, record, index) => {
    //     return (
    //       <Popconfirm
    //         title="Are you sure you want to copy this recipe?"
    //         onConfirm={confirmCopy}
    //       >
    //         <Tooltip title="Copy">
    //         <IconButton>
    //           <FileCopyIcon color="primary" style={{fontSize:"28px"}}/>
    //         </IconButton>
    //         </Tooltip>
    //       </Popconfirm>
    //     );
    //   },
    // },
    // {
    //   title: "Action",
    //   dataIndex: "Action",
    //   key: "status",
    //   align: "center",
    //   render: (_, record, index) => {
    //     return (
    //       <>
    //         <Tooltip title={"Edit Process"}>
    //           <Button size="medium" variant="outline" color="primary">
    //             <VisibilityIcon style={{ color: "orange" }} />
    //           </Button>
    //         </Tooltip>
    //       </>
    //     );
    //   },
    // },
  ];

  const operations = [
    {
      title: "Operation Number",
      dataIndex: "operation_number",
      key: "Operation",
      align: "center",
    },
    {
      title: "Operation",
      dataIndex: "operation_type",
      key: "operation_type",
      align: "center",
      render: (data, record, index) => {
        const ingred = ingredientStrings(record.ingredients, "operation");

        return (
          <div style={{ textAlign: "left" }}>
            <p>
              <strong> Operation Type: </strong>
              {`${data}, `} <br />
              {record?.instruction?.length > 0 && (
                <>
                  <strong>Instructions:</strong>
                  <Tooltip title="View Instructions">
                    <IconButton
                      onClick={() => {
                        Modal.info({
                          title: "Instructions",
                          content: (
                            <ul>
                              {record?.instruction?.map(
                                (instruction, index) => (
                                  <li key={index}>{instruction}</li>
                                )
                              )}
                            </ul>
                          ),
                        });
                      }}
                      disabled={record?.instruction?.length === 0}
                    >
                      <VisibilityIcon
                        color="info"
                        style={{ fontSize: "21px" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <br />
                </>
              )}
              {record?.dependencie && (
                <>
                  <strong> Depending on Operations: </strong>
                  <span>{`${record?.dependencie}`}</span> <br />
                </>
              )}
              {ingred && ingred.length > 0 && (
                <>
                  <strong> Ingredients: </strong>
                  {ingred.join("\n")}
                </>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: "Equipment",
      dataIndex: "machine_type",
      align: "center",
      key: "machine_type",
    },
    {
      title: "Duration",
      dataIndex: "max_duration",
      key: "max_duration",
      align: "center",
    },
    {
      title: "Details / Remarks",
      dataIndex: "products",
      key: "process_products",
      width: "25%",
      align: "center",
      render: (data, record, index) => {
        const ingred = ingredientStrings(record?.ingredients, "");
        let mul = "X Batch Size";
        let perc = "% of Batch Size";
        return (
          <div style={{ textAlign: "left" }}>
            {ingred.length > 0 && (
              <>
                <strong> Ingredients: </strong>
                {/* {ingred?.map((data, index) => (
                      <div key={index}>{data}</div>
                    ))} */}
                <div className="mb-4">
                  {ingred?.map((data, index) => {
                    return (
                      <>
                        <div key={index}>
                          <strong>{`${index + 1}.  `}</strong>
                          {data?.split("-")?.length > 2
                            ? `${data?.split("-")[0]}-${data?.split("-")[1]}`
                            : data?.split("-")[0]}
                          {record?.ingredients[index]?.is_percentage
                            ? "" + perc
                            : record?.ingredients[index]?.material_unit + mul}
                        </div>
                        {data?.split("-")[1] &&
                        data?.split("-")[1] !== "" &&
                        data?.split("-")[1].length !== 1 &&
                        !(data?.split("-")?.length > 2) ? (
                          <div key={index}>
                            <strong>
                              {data?.split("-")[1]?.split(":")[0]}
                              {":"}
                            </strong>
                            {data?.split("-")[1]?.split(":")[1]}
                          </div>
                        ) : data?.split("-")[2] &&
                          data?.split("-")[2].length !== 1 ? (
                          <div key={index}>
                            <strong>
                              {data?.split("-")[2]?.split(":")[0]}
                              {":"}
                            </strong>
                            {data?.split("-")[2]?.split(":")[1]}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const expandedRowRenderoperations = (record) => {
    return (
      <Collapse accordion>
        {record?.process_products?.length > 0 && (
          <>
            <Panel header="Product" key="1">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Product Name",
                    dataIndex: "product_name",
                    key: "product_name",
                  },
                  {
                    title: "Process Thero Quantity",
                    dataIndex: "process_thero_quantity",
                    key: "operation_number",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Min Value",
                    dataIndex: "process_expec_min_value",
                    key: "process_expec_min_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Max Value",
                    dataIndex: "process_expec_max_value",
                    key: "process_expec_max_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                ]}
                dataSource={record?.process_products}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          </>
        )}

        {record?.process_parameters &&
          record?.process_parameters?.length > 0 && (
            <>
              <Panel header="Process Parameters" key="2">
                <Table
                  size="small"
                  pagination={false}
                  columns={[
                    {
                      title: "Parameter",
                      dataIndex: "parameter_name",
                      rowScope: "row",
                    },
                    {
                      title: "Readings Per Interval (HH:MM:SS)",
                      dataIndex: "parameter_max_value",
                      key: "parameter_max_value",
                      render: (data, record, index) => {
                        return <p>{`${data}`}</p>;
                      },
                    },
                    {
                      title: "Quantity",
                      dataIndex: "qunatity",
                      key: "qunatity",
                      align: "center",
                      render: (data, record) => {
                        return <p>{`${data} (${record?.parameter_name_unit})`}</p>;
                      },
                    },
                    {
                      title: "Readings",
                      dataIndex: "reading",
                      key: "min_value",
                      align: "center",
                    },
                    {
                      title: "Min",
                      dataIndex: "min_value",
                      key: "min_value",
                      align: "center",
                      render: (data, record) => {
                        return <p>{ data?`${data} (${record?.parameter_name_unit})`:"NA"}</p>;
                      },
                    },
                    {
                      title: "Max",
                      dataIndex: "max_value",
                      key: "max_value",
                      align: "center",
                      render: (data, record) => {
                        return <p>{ data?`${data} (${record?.parameter_name_unit})`:"NA"}</p>;
                      },
                    },
                  ]}
                  dataSource={record.process_parameters}
                  bordered
                  style={{ width: "100%" }}
                />
              </Panel>
            </>
          )}
        {record?.operation_type === "Sampling" &&
          record?.sampling &&
          record?.sampling?.length > 0 && (
            <Panel header="Sampling" key="3">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Output",
                    align: "center",
                    dataIndex: "material_inventory_name",
                    key: "material_inventory_name",
                  },
                  {
                    title: "Quantity",
                    align: "center",
                    dataIndex: "quantity",
                    key: "quantity",
                    render: (data, record, index) => {
                      return (
                        <p>{`${data} (${record.material_inventory_unit})`}</p>
                      );
                    },
                  },
                  {
                    title: "Testing Type",
                    align: "center",
                    dataIndex: "testing_type_name",
                    key: "testing_type_name",
                  },
                  {
                    title: "Parameter",
                    align: "center",
                    dataIndex: "conditional_param",
                    key: "parameter",
                    render: (data, record) => {
                      return data[0]?.parameter ? data[0].parameter : "NA";
                    },
                  },
                  {
                    title: "Min",
                    align: "center",
                    dataIndex: "conditional_param",
                    key: "min_value",
                    render: (data) => {
                      return data[0]?.min_value ? data[0].min_value : "NA";
                    },
                  },
                  {
                    title: "Max",
                    align: "center",
                    dataIndex: "conditional_param",
                    key: "max_value",
                    render: (data) => {
                      return data[0]?.max_value ? data[0].max_value : "NA";
                    },
                  },
                ]}
                dataSource={record.sampling}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          )}

        {record?.machines && record?.machines?.length > 0 && (
          <Panel header="Machines" key="4">
            <Table
              size="small"
              pagination={false}
              columns={[
                {
                  title: "Machine Name",
                  dataIndex: "machine_name",
                  key: "machine_name",
                },
              ]}
              dataSource={record?.machines}
              bordered
              style={{ width: "100%" }}
            />
          </Panel>
        )}
      </Collapse>
    );
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      set_current_expand(record?.key);
    } else {
      set_current_expand("");
    }
  };
  const [expanded_operations, set_expanded_operations] = useState([]);
  const [loading, set_loading] = useState(true);

  const expandedRowRender = (record) => {
    const updateData = expanded_operations?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });

    return (
      <Table
        columns={operations}
        dataSource={updateData}
        pagination={false}
        loading={loading}
        expandable={{
          expandedRowRender: expandedRowRenderoperations,
          onExpand: handleExpand,
          onExpandedRowsChange: (expanded) => set_expanded(expanded),
          expandedRowKeys: [current_expand],
          expandedRowClassName: () => "col2",
        }}
        bordered
      />
    );
  };

  useEffect(() => {
    //bp_recipe_MetaData();
    bp_product_data(success_bp_product_data);
  }, []);

  const success_bp_product_data = (res) => {
    set_product_list(res?.data?.data);
  };

  return (
    <>
      <Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              onClick={() => handleClick("test")}
              style={buttonStyles(selectedButton === "test")}
            >
              Test
            </Button>
            <Button
              onClick={() => handleClick("production")}
              style={buttonStyles(selectedButton === "production")}
            >
              Production
            </Button>
          </ButtonGroup>
        </Box>
      </Stack>
      <MainCard title="View Process">
        <Grid container spacing={1} style={{ display: "flex" }}>
          <Grid container item xs={12} lg={12} spacing={2}>
            <Grid xs={2} item>
              <Select
                showSearch
                id="demo-select-small"
                value={product_id}
                size="large"
                style={{
                  width: "100%",
                  border: "0.5px solid #D9D9D9",
                  borderRadius: "3px",
                }}
                bordered={false}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                placeholder="Select Product"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.localeCompare(optionB.children)
                }
                onChange={(value, option) => {
                  get_recipe_by_productid(value);
                  set_product_id(value);
                  setSelectedMetadata();
                  set_recipe_id();
                  set_process_expand("");
                }}
              >
                {product_list?.map((mdata) => (
                  <Select.Option
                    key={mdata.product_data_id}
                    value={mdata.product_data_id}
                  >
                    {mdata.product_name}
                  </Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid xs={2} item>
              <Select
                showSearch
                id="demo-select-small"
                value={recipe_id}
                size="large"
                style={{
                  width: "100%",
                  border: "0.5px solid #D9D9D9",
                  borderRadius: "3px",
                }}
                bordered={false}
                placeholder="Select Recipe"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.children.localeCompare(optionB.children)
                }
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
                onChange={(value, option) => {
                  setSelectedMetadata(value);
                  get_Recipe_Data(value);
                  set_recipe_id(value);
                  set_flow_data();
                  setTimeout(() => {
                    set_flow_data({ recipe_id: value });
                  }, 1);
                  set_process_expand("");
                }}
              >
                {metadataList?.map((mdata) => (
                  <Select.Option key={mdata.recipe_id} value={mdata.recipe_id}>
                    {mdata.recipe_name}
                  </Select.Option>
                ))}
              </Select>
            </Grid>
            <Grid xs={8} item>
              <Stack
                direction="row"
                justifyContent="end"
                alignItems="center"
                spacing={1}
              >
                {accessData?.create_access && (
                  <Popconfirm
                    title="Are you sure you want to copy this recipe?"
                    onConfirm={() => confirmCopy(recipe_id)}
                  >
                    <Tooltip title="Copy Selected Recipe">
                      <IconButton disabled={!recipe_id}>
                        <FileCopyIcon
                          color={recipe_id ? "primary" : "secondary"}
                          style={{ fontSize: "28px" }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Popconfirm>
                )}

                {accessData?.read_access && (
                  <Tooltip title={"View BOM"}>
                    <IconButton disabled={!recipe_id} onClick={handleViewBom}>
                      <WysiwygIcon
                        color={recipe_id ? "primary" : "secondary"}
                        sx={{ fontSize: "31px" }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
                {selectedButton === "test" && accessData?.create_access && (
                  <Tooltip title={"Move To Production"}>
                    <IconButton disabled={!recipe_id}>
                      <Popconfirm
                        title="Are you sure to approve recipe for Production ?"
                        onConfirm={confirm}
                        okText="Yes"
                        cancelText="No"
                      >
                        <ThumbUpIcon
                          color={recipe_id ? "primary" : "secondary"}
                          sx={{ fontSize: "31px" }}
                        />
                      </Popconfirm>
                    </IconButton>
                  </Tooltip>
                )}
                {accessData?.create_access && (
                  <Tooltip title={"Add Recipe"}>
                    <IconButton
                      onClick={() => {
                        navigate("/recipeMetadata");
                      }}
                    >
                      <AddIcon color="success" sx={{ fontSize: "35px" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Input.Search
                  Placeholder="Search here..."
                  size="large"
                  style={{ width: "25%" }}
                  onSearch={(value) => {
                    setSearchvalue(value);
                  }}
                  onChange={(e) => setSearchvalue(e.target.value)}
                />
              </Stack>
            </Grid>
          </Grid>
          {recipe_id && (
            <Grid item xs={12} lg={12}>
              <Table
                dataSource={recipeData}
                columns={process_columns}
                pagination={true}
                expandable={{
                  expandedRowRender,
                  onExpand: async (expanded, record) => {
                    set_expanded_operations([]);
                    if (expanded) {
                      set_process_expand(record?.key);
                      set_loading(true);
                      const success_get_operation = (res) => {
                        if (res.data.status === "success") {
                          set_expanded_operations(res.data.data[0].operations);
                        }
                        set_loading(false);
                      };

                      try {
                        if (controllerRef.current) {
                          controllerRef.current.abort();
                        }
                        controllerRef.current = new AbortController();
                        const signal = controllerRef.current.signal;
                        await get_api_function(
                          `/bp_get_all_process_data?recipe_id=${recipe_id}&process_id=${record?.process_id}`,
                          success_get_operation,
                          {},
                          signal
                        );
                      } catch (e) {
                        console.log(e);
                      }
                    } else {
                      set_process_expand("");
                    }
                  },
                  expandedRowKeys: [process_expand],
                }}
                showHeader={true}
                bordered
              />
            </Grid>
          )}
        </Grid>

        <TableInModal
          isVisible={open} // Set to true or false based on visibility
          title={modalTitle}
          handleOk={handleOk}
          handleCancel={handleCancel}
          sourceData={sourceData}
          columnsData={columnData}
        />
        <div>
          <Modal
            open={recipeShow}
            title="New Recipe Name"
            onCancel={handleRecipeCancel}
            footer={[
              <Stack direction="row" spacing={1} justifyContent="end">
                <Button
                  variant="contained"
                  size="medium"
                  key="cancel"
                  onClick={handleRecipeCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  size="medium"
                  key="save"
                  type="primary"
                  onClick={handleRecipeOk}
                >
                  Save
                </Button>
              </Stack>,
            ]}
          >
            <Grid item xs={12} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="des">Recipe Name</InputLabel>
                <OutlinedInput
                  id="des"
                  type="text"
                  size="medium"
                  name="des"
                  placeholder="Enter Recipe Name"
                  fullWidth
                  value={recipe_name}
                  required
                  onChange={(e) => set_recipe_name(e.target.value)}
                />
              </Stack>
            </Grid>
          </Modal>
        </div>
      </MainCard>
    </>
  );
}

export default ViewRecipe;
