import React, { useEffect, useState, useRef } from "react";
import {
  bp_add_recipe_data,
  bp_units_data,
  bp_machine_data,
  getProcessByRecipeId,
  bp_recipe_MetaData_ById,
  bp_reject_recipe_status,
  bp_get_approval_status,
  bp_get_approval_recipe,
  bp_update_process_approval_status,
  get_api_function,
} from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Pagination,
  Input,
  Modal,
  Timeline,
  Typography as Typography1,
  Tag,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableInModal from "components/TableInModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
  Select,
  MenuItem,
  FormControlLabel,
  Grid,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
  Checkbox,
  Tooltip,
  FormGroup,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";

const approval_status_json = {
  true: <CheckCircleOutlined style={{ color: "#5DCC79", fontSize: "16px" }} />,
  false: <ClockCircleOutlined style={{ color: "#FAAE2F", fontSize: "16px" }} />,
};

const status_tag_json = {
  true: <Tag color="success">Approved</Tag>,
  false: <Tag color="warning">Pending</Tag>,
};

function AddRecipe({ recipe_md_id }) {
  const dispatch = useDispatch();
  const [process_expand, set_process_expand] = useState("");
  const controllerRef = useRef();
  dispatch(settab_number("2"));
  const formRef = useRef();
  const [unit, setUnitData] = useState();
  const [machineData, setMachineData] = useState([]);
  const [sourceData, setSourceData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [modalTitle, setModalTitle] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [selectedMetaData, setSelectedMetadata] = useState("i");
  const [searchvalue, setSearchvalue] = useState("");
  const [metadataList, setMetadataList] = useState([]);
  const userDetails = localStorage.getItem("user_details");
  const [comment, setComment] = useState();
  const [commentProcessId, setCommentProcessId] = useState();
  const [aprrovalStatusList, setApprovalProcessList] = useState([]);
  const [isShow, setIsShow] = useState();
  const [role, set_role] = useState("");
  const loginRole = userDetails
    ? JSON.parse(userDetails).roles?.map((role) => role.role_id)
    : [];

  const [all_process_data, set_all_Process_data] = useState();

  // Check if any object's role_id is present in the roleIds array and then check its status
  const hasStatusTrue = (data) =>
    data.some((role) => {
      if (loginRole.includes(role.role_id)) {
        return role.status;
      }
    });

  // Check if any object's role_id is present in the roleIds array and then check its status
  const hasRoleIdFound = (data) =>
    data.some((role) => {
      if (loginRole.includes(role.role_id)) {
        return true;
      }
      return false;
    });

  const handleApproveProcess = (record) => {
    let hasRoleId = record.process_roles?.find((role) =>
      loginRole.includes(role.role_id)
    )?.role_id;
    approve_Process({ role_id: hasRoleId, process_id: record.process_id });
  };

  const handleRejectProcess = (record) => {
    console.log(record);
    setCommentProcessId(record.process_id);
    setIsShow(true);
  };

  const handleRejection = () => {
    reject_recipe_status({
      recipe_id: selectedMetaData,
      process_id: commentProcessId,
      comment: comment,
    }); // call the api
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [approval_data, set_approval_data] = useState([]);

  const columns = [
    {
      title: "Process Name",
      dataIndex: "process_name",
      key: "title",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return String(record.process_name)
          .toLowerCase()
          .includes(value.toLowerCase());
        // String(record.process_roles).toLowerCase().includes(value.toLowerCase()) ||
        // String(record.status).toLowerCase().includes(value.toLowerCase())
      },
      align: "center",
    },
    // {
    //   title: "Process Roles",
    //   dataIndex: "process_roles", // Assuming the key in your data for process_roles is "process_roles"
    //   key: "process_roles",
    //   align: "center",
    //   render: (processRoles) => (
    //     <>
    //       {processRoles.map((role, index) => (
    //         <span key={index}>
    //           {role.role_name}
    //           {index < processRoles.length - 1 ? ", " : ""}
    //         </span>
    //       ))}
    //     </>
    //   ),
    // },
    // {
    //   title: "Approval Status",
    //   dataIndex: "process_roles", // Assuming the key in your data for process_roles is "process_roles"
    //   key: "process_roles",
    //   align: "center",
    //   render: (processRoles) => (
    //     <>
    //       <div style={{ display: "flex", justifyContent: "center" }}>
    //         <Stack direction="row" spacing={2}>
    //           {processRoles &&
    //             processRoles?.some((role) => role.role_name === "R&D") && (
    //               <FormControlLabel
    //                 control={<Checkbox checked={processRoles?.find(role => role.role_name ==="R&D").status}/>}
    //                 label="R&D"
    //               />
    //             )}
    //           {processRoles &&
    //             processRoles?.some((role) => role.role_name === "QA") && (
    //               <FormControlLabel
    //               control={<Checkbox checked={processRoles?.find(role => role.role_name ==="QA").status}/>}
    //               label="QA" />
    //             )}
    //           {processRoles &&
    //             processRoles?.some((role) => role.role_name === "Admin") && (
    //               <FormControlLabel
    //               control={<Checkbox checked={processRoles?.find(role => role.role_name ==="Admin").status}/>}
    //                 label="Production" />
    //             )}
    //         </Stack>
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "View Approval",
      dataIndex: "",
      key: "title",
      align: "center",
      render: (_, record, index) => {
        return (
          <Tooltip title="Approve">
            <IconButton
              onClick={() => {
                setIsModalOpen(true);
                set_approval_data(record?.process_roles);
              }}
            >
              <VisibilityIcon color="primary" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "action",
      align: "center",
      render: (_, record, index) => {
        return (
          <>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {/* //here checking the if role id is found in login user roll id */}
              {/* {hasRoleIdFound(record?.process_roles) ? ( */}

              {!record?.comment ? (
                <>
                  {/* // here we are checking the if role is found then checking that roles status is he approved or not */}

                  {!hasStatusTrue(record?.process_roles) ? (
                    <>
                      <Tooltip title={"Approve Process"}>
                        <IconButton
                          onClick={() => handleApproveProcess(record)}
                        >
                          <CheckCircleIcon sx={{ color: "#00C853" }} />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={"Reject Process"}>
                        <IconButton onClick={() => handleRejectProcess(record)}>
                          <CancelIcon color="error" />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <Tooltip title={"Approved"}>
                      <DoneOutlineIcon color="success" />
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  <Tooltip title={"Rejected"}>
                    <CloseIcon color="error" />
                  </Tooltip>
                </>
              )}
            </Stack>
          </>
        );
      },
    },
  ];

  const ingredientStrings = (data, header) => {
    return data?.map((ingredient) => {
      let ingredientString = `${ingredient.material_name} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist

        ingredientString +=
          ingredient.parameter_name && `(${ingredient.parameter_name}), `;
      } else {
        ingredientString += `${ingredient.quantity + " "}${
          ingredient.material_unit
        }`;
        if (
          ingredient.parameter_name !== "" &&
          ingredient.min_value >= 0 &&
          ingredient.max_value > 0
        ) {
          ingredientString += ` (${ingredient.parameter_name} with min ${ingredient.min_value}${ingredient.parameter_name_unit} and max ${ingredient.max_value}${ingredient.parameter_name_unit})`;
        }
      }
      return ingredientString;
    });
  };

  const operationsColumns = [
    {
      title: "Operation Number",
      dataIndex: "operation_number",
      key: "Operation",
      width: "10%",
      align: "center",
    },
    {
      title: "Operation",
      dataIndex: "operation_type",
      width: "20%",
      key: "operation_type",
      align: "center",
      render: (data, record, index) => {
        const ingred = ingredientStrings(record.ingredients, "operation");

        return (
          <div style={{ textAlign: "left" }}>
            <p>
              {record?.operation_name && (
                <>
                  <strong> Operation : </strong>
                  <span>{`${record?.operation_name}, `}</span> <br />
                </>
              )}
              <strong> Operation Type: </strong>
              {`${data}, `} <br />
              {record?.instruction?.length > 0 && (
                <>
                  <strong>Instructions:</strong>
                  <Tooltip title="View Instructions">
                    <IconButton
                      onClick={() => {
                        Modal.info({
                          title: "Instructions",
                          content: (
                            <ul>
                              {record?.instruction?.map(
                                (instruction, index) => (
                                  <li key={index}>{instruction}</li>
                                )
                              )}
                            </ul>
                          ),
                        });
                      }}
                      disabled={record?.instruction?.length === 0}
                    >
                      <VisibilityIcon
                        color="info"
                        style={{ fontSize: "21px" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <br />
                </>
              )}
              {record?.dependencie && (
                <>
                  <strong> Depending on Operations: </strong>
                  <span>{`${record?.dependencie}`}</span>
                  <br />
                </>
              )}
            </p>
            <p>
              {ingred && ingred?.length > 0 && (
                <>
                  <strong> Ingredients: </strong>
                  {ingred.join("\n")}
                </>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: "Equipment",
      dataIndex: "machine_type",
      key: "machine_type",
    },
    {
      title: "Duration",
      dataIndex: "max_duration",
      key: "max_duration",
      align: "center",
    },
    {
      title: "Details / Remarks",
      dataIndex: "process_products",
      key: "process_products",
      align: "center",
      render: (data, record, index) => {
        const columns = [
          {
            title: "Product Name",
            dataIndex: "product_name",
            key: "product_name",
          },
          {
            title: "Process Thero Quantity",
            dataIndex: "process_thero_quantity",
            key: "operation_number",
            render: (data, record, index) => {
              return <p>{`${data} (${record.product_name_unit})`}</p>;
            },
          },
          {
            title: "Expected Min Value",
            dataIndex: "process_expec_min_value",
            key: "process_expec_min_value",
            render: (data, record, index) => {
              return <p>{`${data} (${record.product_name_unit})`}</p>;
            },
          },
          {
            title: "Expected Max Value",
            dataIndex: "process_expec_max_value",
            key: "process_expec_max_value",
            render: (data, record, index) => {
              return <p>{`${data} (${record.product_name_unit})`}</p>;
            },
          },
        ];

        const machineCols = [
          // {
          //   title: "Machine Id",
          //   dataIndex: "machine_id",
          //   key: "machine_name",
          // },
          {
            title: "Machine Name",
            dataIndex: "machine_name",
            key: "machine_name",
          },
        ];

        const pr_columns = [
          {
            title: "Parameter",
            dataIndex: "parameter_name",
            rowScope: "row",
          },
          {
            title: "Readings Per Interval (HH:MM:SS)",
            dataIndex: "parameter_max_value",
            key: "parameter_max_value",
            render: (data, record, index) => {
              return <p>{`${data}`}</p>;
            },
          },
          {
            title: "Quantity",
            dataIndex: "qunatity",
            key: "qunatity",
            align: "center",
            render: (data, record) => {
              return <p>{`${data} (${record?.parameter_name_unit})`}</p>;
            },
          },
          {
            title: "Readings",
            dataIndex: "reading",
            key: "min_value",
            align: "center",
          },
          {
            title: "Min",
            dataIndex: "min_value",
            key: "min_value",
            align: "center",
            render: (data, record) => {
              return <p>{ data?`${data} (${record?.parameter_name_unit})`:"NA"}</p>;
            },
          },
          {
            title: "Max",
            dataIndex: "max_value",
            key: "max_value",
            align: "center",
            render: (data, record) => {
              return <p>{ data?`${data} (${record?.parameter_name_unit})`:"NA"}</p>;
            },
          },
        ];

        const samplingCols = [
          {
            title: "Output",
            align: "center",
            dataIndex: "material_inventory_name",
            key: "material_inventory_name",
          },
          {
            title: "Quantity",
            align: "center",
            dataIndex: "quantity",
            key: "quantity",
            render: (data, record, index) => {
              return <p>{`${data} (${record.material_inventory_unit})`}</p>;
            },
          },
          {
            title: "Department",
            align: "center",
            dataIndex: "to",
            key: "to",
          },
          {
            title: "Testing Type",
            align: "center",
            dataIndex: "testing_type_name",
            key: "testing_type_name",
          },
        ];

        const ingred = ingredientStrings(record.ingredients, "");
        return (
          <div style={{ textAlign: "left" }}>
            {ingred.length > 0 && (
              <>
                <strong> Ingredients: </strong>
                <div className="mb-4">{ingred.join("\n")}</div>
              </>
            )}

            {data && data.length > 0 && (
              <>
                <div className="mb-3">
                  <strong> Product: </strong>
                </div>
                <div style={{ margin: 10, marginLeft: -35 }}>
                  <Table
                    size="small"
                    pagination={false}
                    columns={columns}
                    dataSource={data}
                    bordered
                    style={{ width: "100%" }}
                  />
                </div>
                <br />
              </>
            )}
            {record.process_parameters &&
              record.process_parameters.length > 0 && (
                <>
                  <div className="mb-3">
                    <strong> Process Parameters: </strong>
                  </div>
                  <div style={{ margin: 10, marginLeft: -35 }}>
                    <Table
                      size="small"
                      pagination={false}
                      columns={pr_columns}
                      dataSource={record.process_parameters}
                      bordered
                      style={{ width: "100%" }}
                    />
                  </div>
                  <br />
                </>
              )}

            {record?.operation_type === "Sampling" &&
              record?.sampling &&
              record?.sampling.length > 0 && (
                <>
                  <div className="mb-3">
                    <strong> Sampling: </strong>
                  </div>
                  <div style={{ margin: 10, marginLeft: -35 }}>
                    <Table
                      size="small"
                      pagination={false}
                      columns={samplingCols}
                      dataSource={record.sampling}
                      bordered
                      style={{ width: "100%" }}
                    />
                  </div>
                </>
              )}

            {record.machines && record.machines.length > 0 && (
              <>
                <div className="mb-3">
                  <strong> Machines: </strong>
                </div>
                <div style={{ margin: 10, marginLeft: -35 }}>
                  <Table
                    size="small"
                    pagination={false}
                    columns={machineCols}
                    dataSource={record.machines}
                    bordered
                    style={{ width: "100%" }}
                  />
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  //####################### Handle Api's######################################################

  const approve_Process = async (approvalReq) => {
    try {
      await bp_update_process_approval_status(
        approvalReq,
        success_update_process
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_update_process = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      set_all_Process_data([]);
      get_all_DropDown_data();
      setSelectedMetadata("i");
    }
  };

  const reject_recipe_status = async (rejectReq) => {
    try {
      await bp_reject_recipe_status(rejectReq, success_reject_process);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_reject_process = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setCommentProcessId("");
      setSelectedMetadata("i");
      setComment("");
      setIsShow(false);
      set_all_Process_data([]);
      get_all_DropDown_data();
      toast.success(res.message);
    }
  };

  const get_all_process_data = async (recipe_id) => {
    try {
      // await getProcessByRecipeId(recipe_id, success_all_process_list_get);
      await get_api_function(
        `/get_process_list_by_recipe?recipe_id=${recipe_id}`,
        success_all_process_list_get
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_all_process_list_get = (res) => {
    if (res.data.data && res.data.data.length > 0) {
      const proessList = res?.data?.data || [];

      const filteredProcessList = proessList?.filter((process) => {
        return process.process_roles?.some((role) =>
          loginRole?.includes(role.role_id)
        );
      });

      set_all_Process_data(addKeysInFilterDataForCollapse(filteredProcessList));
    } else {
      set_all_Process_data([]);
    }
  };

  const successMetaData = (res) => {
    setMetadataList(res.data.data);
  };

  const get_all_DropDown_data = async () => {
    try {
      await bp_get_approval_recipe(successMetaData);
      await bp_get_approval_status(successApprovalStatusList);
      await bp_units_data(success_unit_list_get);
      await bp_machine_data(success_machine_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_list_get = (res) => {
    setMachineData(res?.data?.data);
  };

  const successApprovalStatusList = (res) => {
    setApprovalProcessList(res?.data?.data);
  };

  const success_unit_list_get = (res) => {
    setUnitData(res?.data?.data);
  };

  useEffect(() => {
    get_all_DropDown_data();
  }, []);

  // -----------------------------

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleook = () => {
    setIsVisible(false);
  };

  const handleCancell = () => {
    setIsVisible(false);
  };

  // ==========================================

  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expanded_operations, set_expanded_operations] = useState([]);
  const [loading, set_loading] = useState(true);

  const expandedRowRender = (record) => {
    const updateData = expanded_operations?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });

    return (
      <Table
        columns={operationsColumns}
        dataSource={updateData}
        pagination={false}
        loading={loading}
        bordered
      />
    );
  };

  return (
    <MainCard title="Process Approval">
      <Grid container spacing={1} columns={12} style={{ display: "flex" }}>
        <Grid item xs={12} lg={12}>
          <Stack direction="row" spacing={1}>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={selectedMetaData}
              style={{ width: 230 }}
              required
              startAdornment={
                <InputAdornment position="start">Recipe :</InputAdornment>
              }
              onChange={(e) => {
                setSelectedMetadata(e.target.value);
                get_all_process_data(e.target.value);
                set_role(
                  metadataList?.find(
                    (data) => data.recipe_id === e.target.value
                  ).role_name
                );
              }}
            >
              <MenuItem value="i" disabled>
                <em> Select Recipe</em>
              </MenuItem>
              {metadataList?.map((mdata) => (
                <MenuItem value={mdata.recipe_id}>
                  {mdata.recipe_name}
                  {/* <Tooltip
                  title={
                    mdata.approval_status === "Approved"
                      ? "Approved"
                      : mdata.approval_status === "Pending"
                      ? "Pending"
                      : "Empty"
                  }
                >
                  <FiberManualRecordIcon
                    style={{
                      color:
                        mdata.approval_status === "Approved"
                          ? "Green"
                          : mdata.approval_status === "Pending"
                          ? "Orange"
                          : "inherit",
                      fontSize: "small",
                    }}
                  ></FiberManualRecordIcon>
                </Tooltip> */}
                </MenuItem>
              ))}
            </Select>
            <div style={{ marginLeft: "auto" }}>
              <Input.Search
                Placeholder="Search here..."
                className="mt-1"
                size="large"
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </div>
          </Stack>
        </Grid>

        <Grid item lg={12}>
          <Table
            dataSource={all_process_data}
            columns={columns}
            pagination={false}
            expandable={{
              expandedRowRender,
              onExpand: async (expanded, record) => {
                set_expanded_operations([]);
                if (expanded) {
                  set_process_expand(record?.key);
                  set_loading(true);
                  const success_get_operation = (res) => {
                    if (res.data.status === "success") {
                      set_expanded_operations(res.data.data[0].operations);
                    }
                    set_loading(false);
                  };

                  try {
                    if (controllerRef.current) {
                      controllerRef.current.abort();
                    }
                    controllerRef.current = new AbortController();
                    const signal = controllerRef.current.signal;
                    await get_api_function(
                      `/bp_get_all_process_data?recipe_id=${selectedMetaData}&process_id=${record?.process_id}`,
                      success_get_operation,
                      {},
                      signal
                    );
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  set_process_expand("");
                }
              },
              expandedRowKeys: [process_expand],
            }}
            showHeader={true}
            bordered
          />
        </Grid>

        {/* <Grid item lg={12}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              type="button"
              variant="contained"
              onClick={(e) => handleSubmit()}
              color="primary"
            >
              Submit
            </Button>
          </Stack>
        </Grid> */}
      </Grid>
      <Modal
        open={isShow}
        title="Rejection Comment"
        width={500}
        onOk={() => handleRejection()}
        onCancel={() => {
          setComment("");
          setIsShow(!isShow);
        }}
      >
        <Grid container xs={12} lg={12}>
          <Grid item xs={8}>
            <OutlinedInput
              id="des"
              type="text"
              size="small"
              name="des"
              placeholder="Comment"
              fullWidth
              value={comment}
              required
              onChange={(e) => setComment(e.target.value)}
            />
          </Grid>
        </Grid>
      </Modal>

      <Modal
        title="Approvals"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Timeline
          mode="alternate"
          items={approval_data?.map((item) => {
            return {
              dot: approval_status_json[item?.status],
              children: (
                <>
                  <Typography1>{item?.role_name}</Typography1>
                  {status_tag_json[item?.status]}
                </>
              ),
            };
          })}
        />
      </Modal>

      <TableInModal
        isVisible={isVisible} // Set to true or false based on visibility
        title={modalTitle}
        handleOk={handleook}
        handleCancel={handleCancell}
        sourceData={sourceData}
        columnsData={columnData}
      />
    </MainCard>
  );
}

export default AddRecipe;
