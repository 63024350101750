import React, { useEffect, useState, useRef } from "react";
import {
  get_sensor_list,
  sensor_configuration,
  update_sensor_status,
  bp_units_data,
  get_machine_category,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { Table, Pagination, Input, Switch, Select } from "antd";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ConfigModal from "./ConfigModal";
import { useNavigate } from "react-router-dom";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { accessControlUtils } from "components/AccessControlUtils";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  IconButton,
  MenuItem,
  Grid,
  Chip,
  Button,
} from "@mui/material";
import AddIngredient from "./AddIngredients";
import { indexOf } from "lodash";
import MainCard from "components/MainCard";

function SensorView({ show_config }) {
  const navigate = useNavigate();
  const formRef = useRef();
  const [sensorData, setsensorData] = useState();
  const [show_form, set_show_form] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [show_modal, set_show_modal] = useState(false);
  const [get_submit_status, set_get_submit_status] = useState(false);
  const onChange = (checked, record) => {
    update_sensor({ sensor_id: record?.sensor_id, sensor_status: checked });
  };

  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [make, setMake] = useState("");
  const [sensorStatus, setSensorStatus] = useState("");

  const [unitData, setUnitsData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [get_status, set_get_status] = useState(false);

  const [sensorReq, setsensorReq] = useState({
    name: "",
    category_id: "",
    unit_id: "",
    make: "",
    sensor_status: "",
  });

  const sensorDetailColumns = [
    {
      dataIndex: "sensor_name",
      title: "Sensor Name",
      key: "name",
      width: "8%",
      align: "center",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record.sensor_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.category).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "category",
      title: "Sensor Category",
      key: "category",
      align: "center",
      width: "8%",
    },
    {
      dataIndex: "unit",
      title: "Unit",
      key: "unit",
      align: "center",
      width: "8%",
    },
    {
      dataIndex: "make",
      title: "Make",
      key: "make",
      align: "center",
      width: "8%",
    },
    {
      dataIndex: "status",
      title: "Status",
      key: "status",
      width: "8%",
      align: "center",
      render: (data, record, index) => {
        return (
            <Tooltip title={data? "InActive":"Active"}>
          <Switch
            checked={data}
            disabled={!accessControlUtils("Configuration")?.create_access}
            onChange={(checked) => onChange(checked, record)}
          /></Tooltip>
        );
      },
    },
  ];

  //####################### Handle Api's######################################################

  const get_all_sensor = async () => {
    try {
      await get_sensor_list(success_sensor_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_sensor_list_get = (res) => {
    console.log("res", res);
    setsensorData(res?.data?.data);
  };

  const add_sensor_Data = async (req) => {
    try {
      await sensor_configuration(req, success_add_sensor_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const get_all_dropDown_Data = async () => {
    try {
      await bp_units_data(success_units_list_get);
      await get_machine_category(success_get_all_categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_units_list_get = (res) => {
    setUnitsData(res?.data?.data);
  };

  const success_get_all_categories = (res) => {
    setCategoryList(res?.data?.data);
  };

  const success_add_sensor_data = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      reset_function();
      get_all_sensor();
    } else {
      toast.warning(res?.message);
    }
  };
  const update_sensor = async (req) => {
    try {
      await update_sensor_status(req, success_update_sensor_status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_update_sensor_status = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_all_sensor();
    } else {
      toast.warning(res?.message);
    }
  };

  const handleSubmit = () => {
    const req = {
      name: name,
      category_id: categoryId,
      unit_id: unitId,
      make: make,
      sensor_status: sensorStatus === "Active" ? true : false,
    };

    add_sensor_Data(req);
  };

  const reset_function = () => {
    setName("");
    setCategoryId("");
    setMake("");
    setSensorStatus("");
    setUnitId("");
  };

  useEffect(() => {
    get_all_sensor();
    get_all_dropDown_Data();
  }, []);

  return (
    <>
      <MainCard title="Sensor">
        {show_form && (
          <Stack direction="row" justifyContent="end">
            <Tooltip title="Close" style={{ marginTop: "-45px" }}>
              <IconButton onClick={() => set_show_form(!show_form)}>
                <CloseIcon color="error" style={{ fontSize: "35px" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        )}

        {show_form && (
          <form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{ display: "flex" }}>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Sensor ID / Name</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="text"
                    name="size"
                    placeholder="Enter sensor ID or name"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Category</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={categoryId || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Material Type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.localeCompare(optionB.children)
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => setCategoryId(value)}
                    >
                      {categoryList?.map((mdata) => (
                        <Select.Option
                          key={mdata.category_id}
                          value={mdata.category_id}
                        >
                          {mdata.category}
                        </Select.Option>
                      ))}
                    </Select>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Make</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="text"
                    name="size"
                    placeholder="Enter Manufacturer"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={make}
                    required
                    onChange={(e) => setMake(e?.target.value)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Unit</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={unitId || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Unit"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.localeCompare(optionB.children)
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => setUnitId(value)}
                    >
                      {unitData?.map((mdata) => (
                        <Select.Option key={mdata.id} value={mdata.id}>
                          {mdata.unit_name}
                        </Select.Option>
                      ))}
                    </Select>
                    <Tooltip title="Add Unit">
                      <IconButton onClick={() => set_show_modal(true)}>
                        <AddIcon
                          style={{
                            color: "#00C853",
                            fontSize: "32px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Status</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={sensorStatus || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Status"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      //   filterSort={(optionA, optionB) =>
                      //     optionA.children.localeCompare(optionB.children)
                      //   }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => setSensorStatus(value)}
                    >
                      {[{ status: "Active" }, { status: "InActive" }]?.map(
                        (mdata) => (
                          <Select.Option
                            key={mdata.status}
                            value={mdata.status}
                          >
                            {mdata.status}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={1}
                  sx={{ m: 1 }}
                >
                  <Button
                    size="small"
                    type="reset"
                    variant="contained"
                    color="primary"
                    className="mx-2"
                    onClick={reset_function}
                  >
                    Reset
                  </Button>
                  <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      !(name && categoryId && make && unitId && sensorStatus)
                    }
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="end"
              alignItems="center"
            >
              {accessControlUtils("Configuration")?.create_access && (
                <Tooltip title="Add Sensor">
                  <IconButton onClick={() => set_show_form(!show_form)}>
                    {!show_form && (
                      <AddIcon color="primary" style={{ fontSize: "35px" }} />
                    )}
                  </IconButton>
                </Tooltip>
              )}
              <Input.Search
                Placeholder="Search here..."
                className="my-1"
                size="large"
                style={{ width: "15%" }}
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Table
              dataSource={sensorData}
              columns={sensorDetailColumns}
              size="small"
              bordered
              pagination={true}
            />
          </Grid>

          <ConfigModal
            show_modal={show_modal}
            set_show_modal={set_show_modal}
            add_number={2}
            get_status={get_status}
            set_get_status={set_get_status}
          />
        </Grid>
      </MainCard>
    </>
  );
}

export default SensorView;
