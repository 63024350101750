import React, { useEffect, useState } from "react";
import MainCard from "components/MainCard";
import {
  Steps,
  Descriptions,
  Table,
  Empty,
  Flex,
  ConfigProvider,
  Modal,
  Input,
  Timeline,
  Typography as Typography1,
  Tag,
} from "antd";
import {
  IconButton,
  Stack,
  OutlinedInput,
  Tooltip,
  Button,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  bp_get_approvel_status_for_batch,
  bp_get_batch_approval_details,
  bp_update_approval_status_for_recipe,
} from "utils/api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { toast } from "react-toastify";
import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";

const approval_status_json = {
  approve: (
    <CheckCircleOutlined style={{ color: "#5DCC79", fontSize: "16px" }} />
  ),
  Pending: (
    <ClockCircleOutlined style={{ color: "#FAAE2F", fontSize: "16px" }} />
  ),
};

const status_tag_json = {
  approve: <Tag color="success">Approved</Tag>,
  Pending: <Tag color="warning">Pending</Tag>,
};

const RecipeViewApproval = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [record, set_record] = useState("");
  const [loading, set_loading] = useState(false);
  const [recipe_data, set_recipe_data] = useState([]);

  const get_all_dropDown_Data_on_submit = async () => {
    try {
      set_loading(true);
      await bp_get_batch_approval_details(success_recipe_data);
    } catch (error) {
      set_loading(false);
      console.error("Error fetching data:", error);
    }
  };

  const success_recipe_data = (res) => {
    set_loading(false);
    set_recipe_data(res?.data?.data || []);
  };

  const success = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      try {
        await bp_get_batch_approval_details(success_recipe_data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  useEffect(() => {
    get_all_dropDown_Data_on_submit();
  }, []);

  const [comment, set_comment] = useState("");

  const warning = (record) => {
    Modal.warning({
      title: "Comment",
      cancelText: "Cancel",
      closable: true,
      maskClosable: true,
      content: (
        <Input
          placeholder="Enter Comment"
          onChange={(e) => {
            set_comment(e.target.value);
          }}
        />
      ),

      onOk() {
        bp_update_approval_status_for_recipe(
          {
            comment: comment,
            batch_approval_id: record?.batch_approval_id,
            approval_status: false,
          },
          success
        );
      },
    });
  };

  const columns1 = [
    {
      title: "Batch Number",
      dataIndex: "batch_number",
      key: "batch_number",
      align: "center",
    },
    {
      title: "Recipe Name",
      dataIndex: "recipe_name",
      key: "recipe_name",
      align: "center",
    },
    {
      title: "Process Name",
      dataIndex: "process_name",
      key: "process_name",
      align: "center",
    },
    {
      title: "View Approvals",
      key: "view_approvals",
      align: "center",
      render: (_, record) => {
        return (
          <Tooltip title="Approve">
            <IconButton
              onClick={() => {
                setIsModalOpen(true);
                set_record(record);
              }}
            >
              <VisibilityIcon color="primary" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    // {
    //   title: "Action",
    //   key: "action",
    //   align: "center",
    //   render: (_, record) => {
    //     return (
    //       <Stack direction="row" spacing={2} justifyContent="center">
    //         <Tooltip title="Approve">
    //           <IconButton
    //             onClick={() => {
    //               bp_update_approval_status_for_recipe(
    //                 {
    //                   batch_approval_id: record?.batch_approval_id,
    //                   approval_status: true,
    //                 },
    //                 success
    //               );
    //             }}
    //           >
    //             <CheckCircleIcon style={{ color: "green" }} />
    //           </IconButton>{" "}
    //         </Tooltip>
    //         <Tooltip title="Reject">
    //           <IconButton
    //             onClick={() => {
    //               warning(record);
    //             }}
    //           >
    //             <CancelIcon style={{ color: "red" }} />
    //           </IconButton>
    //         </Tooltip>
    //       </Stack>
    //     );
    //   },
    // },
  ];

  const get_Ingredients = (record) => {
    return (
      <Table
        size="small"
        bordered
        title={() => "Ingredients"}
        dataSource={record?.ingredients}
        columns={[
          {
            title: "Ingredient Name",
            dataIndex: "ingredient_name",
            key: "ingredient_name",
          },
          {
            title: "Condition",
            dataIndex: "conditinal_para",
            key: "conditinal_para",
          },
          {
            title: "Quantity",
            key: "quantity",
            render: (_, record) =>
              `${record?.ingredient_qty}${" "}${record?.unit}`,
          },
        ]}
        pagination={false}
      />
    );
  };

  const get_sampling = (record) => {
    return (
      <Table
        size="small"
        bordered
        title={() => "Sampling"}
        dataSource={record?.sampling}
        columns={[
          {
            title: "Material Inventory Name",
            dataIndex: "material_inventory_name",
            key: "material_inventory_name",
          },
          {
            title: "Testing Type Name",
            dataIndex: "testing_type_name",
            key: "testing_type_name",
          },
          {
            title: "Sample Number",
            dataIndex: "sample_number",
            key: "sample_number",
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (_, record) =>
              `${record?.quantity}${" "}${record?.material_inventory_unit}`,
          },
          {
            title: "Sample Quantity",
            dataIndex: "sample_quantity",
            key: "sample_quantity",
            render: (_, record) =>
              `${record?.sample_quantity}${" "}${
                record?.material_inventory_unit
              }`,
          },
        ]}
        pagination={false}
      />
    );
  };

  const get_Product = (record) => {
    return (
      <p style={{ margin: 0 }}>
        <Table
          size="small"
          bordered
          title={() => "Product"}
          dataSource={record?.products}
          columns={[
            {
              title: "Product Name",
              dataIndex: "product_name",
              key: "product_name",
            },
            {
              title: "value",
              key: "value",
              render: (_, record) => {
                return (
                  record?.value?.length > 0 && (
                    <Flex gap="20px" vertical={true} justify="flex-start">
                      <Table
                        size="small"
                        bordered
                        dataSource={record?.value}
                        columns={[
                          {
                            title: "Container Id",
                            dataIndex: "container_id",
                            key: "created_on",
                          },
                          {
                            title: "Tare Wt",
                            dataIndex: "tare_weight",
                            key: "value",
                          },
                          {
                            title: "Gross Wt",
                            dataIndex: "gross_weight",
                            key: "value",
                          },
                          {
                            title: "Net Wt",
                            dataIndex: "net_weight",
                            key: "value",
                          },
                        ]}
                        pagination={false}
                      />
                    </Flex>
                  )
                );
              },
            },
          ]}
          pagination={false}
        />
      </p>
    );
  };

  const get_Parmeters = (record) => {
    return (
      <Table
        size="small"
        bordered
        title={() => "Parameters"}
        dataSource={record?.paramaters}
        columns={[
          {
            title: "Parameter Name",
            dataIndex: "parameter_name",
            key: "parameter_name",
          },
          {
            title: "value",
            key: "value",
            render: (_, record) => {
              return (
                record?.value?.length > 0 && (
                  <Table
                    size="small"
                    bordered
                    dataSource={record?.value}
                    columns={[
                      {
                        title: "Created On",
                        dataIndex: "created_on",
                        key: "created_on",
                        render: (created_on) => created_on?.slice(0, 19),
                      },
                      {
                        title: "Value",
                        dataIndex: "value",
                        key: "value",
                      },
                    ].filter(Boolean)}
                    pagination={false}
                  />
                )
              );
            },
          },
        ]}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: "Op No",
      dataIndex: "operation_number",
      key: "operation_number",
    },
    {
      title: "Operation",
      dataIndex: "operation_type",
      key: "operation_type",
    },
    {
      title: "Machine",
      dataIndex: "machine_name",
      key: "machine_id",
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
    },
    {
      title: "Duration",
      dataIndex: "total_duration",
      key: "total_duration",
      render: (_, record) => {
        return (
          <Descriptions
            size="small"
            column={1}
            bordered
            items={[
              {
                key: 0,
                label: "Min",
                children: record?.min_duration,
              },
              {
                key: 1,
                label: "Max",
                children: record?.max_duration,
              },
              {
                key: 2,
                label: "Total",
                children: record?.total_duration,
              },
            ]}
          />
        );
      },
    },
    {
      title: "Details ",
      key: "detailsRemarks",
      align: "left",
      render: (_, record) => {
        return (
          <Flex gap="20px" vertical={true} justify="flex-start">
            {record?.ingredients?.length > 0 && get_Ingredients(record)}
            <ConfigProvider
              theme={{
                components: {
                  Table: {
                    cellPaddingInline: 0,
                  },
                },
              }}
            >
              {record?.paramaters?.length > 0 && get_Parmeters(record)}
            </ConfigProvider>
            {record?.products?.length > 0 && get_Product(record)}
            {record?.sampling?.length > 0 && get_sampling(record)}
          </Flex>
        );
      },
    },
  ];

  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  return (
    <>
      <Table
        columns={columns1}
        bordered
        dataSource={addKeysInFilterDataForCollapse(recipe_data)}
        loading={loading}
        // expandable={{ expandedRowRender: expandableRowRender, indentSize: 0 }}
      />
      <Modal
        title="Approvals"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
      >
        <Timeline
          mode="alternate"
          items={record?.status_list?.map((item) => {
            return {
              dot: approval_status_json[item?.approval_status],
              children: (
                <>
                  <Typography1>{item?.role_name}</Typography1>
                  {status_tag_json[item?.approval_status]}
                </>
              ),
            };
          })}
        />
      </Modal>
    </>
  );
};

export default RecipeViewApproval;
